import $ from "jquery";

/**
 * --------------------------------------------------------------------------
 * XXXXXXXX (v4.0.0): brandnavhead.js
 * Licensed under YYYYYYYY
 * --------------------------------------------------------------------------
 */

const BrandNavHead = ($ => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = "brandnavhead";
  const VERSION = "4.0.0";
  const DATA_KEY = "tc.brandnavhead";
  const EVENT_KEY = `.${DATA_KEY}`;
  const NAV_DATA_KEY = "tc.brandnav";
  const NAV_EVENT_KEY = `.${NAV_DATA_KEY}`;
  const DATA_API_KEY = ".data-api";
  const JQUERY_NO_CONFLICT = $.fn[NAME];

  const Selector = {
    SPY: '[data-spy="brandnav"]',
    OPEN: '[data-open="brandnav"]',
  };

  const Event = {
    NAV_HIDE: `hide${NAV_EVENT_KEY}`,
    NAV_SHOW: `show${NAV_EVENT_KEY}`,
    CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`,
    LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
  };

  const ClassName = {
    BRAND_NAV_HEAD: "brandnavhead"
  };

  const DEFAULTS = {
    target: window
  };

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class BrandNavHead {
    constructor(element, options) {
      this.options = $.extend({}, DEFAULTS, options);

      this.$target = $(this.options.target)
        .on(Event.NAV_SHOW, $.proxy(this.update, this))
        .on(Event.NAV_HIDE, $.proxy(this.update, this));

      this.$element = $(element);

      this.$label = this.$element.find(".brandnav-label");
      this.$controlUp = this.$element.find(".brandnav-control-up");

      this.storeLabel();
    }

    // Getters

    static get VERSION() {
      return VERSION;
    }

    // Public

    update(e) {
      this.updateLabel(e);
      this.updateControls(e);
    }

    updateLabel(e) {
      var text = e.target.siblings(Selector.OPEN).text();
      this.$label.text(text || this.$label.data("root-label"));
    }

    updateControls(e) {
      this.$controlUp.toggleClass("in", !!e.target.parents(".brandnav").length);
    }

    // Private

    storeLabel(e) {
      this.$label.data("root-label", this.$label.text());
    }

    // Static

    static loadHandler() {
      $(Selector.SPY).each(function() {
        var $spy = $(this);
        BrandNavHead._jQueryInterface.call($spy, $spy.data());
      });
    }

    static clickHandler(e) {
      e.preventDefault(); // only accept click events on button inside
    }

    static _jQueryInterface(config) {
      return this.each(function() {
        const $element = $(this);
        let data = $element.data(DATA_KEY);

        if (!data) {
          data = new BrandNavHead(this);
          $element.data(DATA_KEY, data);
        }
      });
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document).on(
    Event.CLICK_DATA_API,
    Selector.SPY,
    BrandNavHead.clickHandler
  );

  $(window).on(
    Event.LOAD_DATA_API,
    BrandNavHead.loadHandler
  );

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = BrandNavHead._jQueryInterface;
  $.fn[NAME].Constructor = BrandNavHead;
  $.fn[NAME].noConflict = function() {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return BrandNavHead._jQueryInterface;
  };

  return BrandNavHead;
})($);

export default BrandNavHead;

import $ from "jquery";

/**
 * --------------------------------------------------------------------------
 * XXXXXXXX (v4.0.0): brandnav.js
 * Licensed under YYYYYYYY
 * --------------------------------------------------------------------------
 */

const BrandNav = ($ => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = "brandnav";
  const VERSION = "4.0.0";
  const DATA_KEY = "tc.brandnav";
  const EVENT_KEY = `.${DATA_KEY}`;
  const DATA_API_KEY = ".data-api";
  const JQUERY_NO_CONFLICT = $.fn[NAME];

  const Selector = {
    OPEN: '[data-open="brandnav"]',
    CLOSE: '[data-close="brandnav"]',
    CANCEL: '[data-cancel="brandnav"]'
  };

  const Event = {
    CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`
  };

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class BrandNav {
    constructor(element) {
      this.$element = $(element);
      this.$parent = this.$element.parent();
      this.$parentnav = this.$element.parents(".brandnav").first();

      this.$main = BrandNav.getMain(this.$element);
      this.$body = $("body");
    }

    // Getters

    static get VERSION() {
      return VERSION;
    }

    // Public

    show() {
      var that = this;

      var showEvent = $.Event("show.tc.brandnav", {
        target: this.$element,
        relatedTarget: this.$parentnav
      });

      this.$main.trigger(showEvent);
      if (showEvent.isDefaultPrevented()) return;

      this.$body.css("overflow-x", "hidden");
      this.$parent.siblings().removeClass("active");

      this.$parent.addClass("active");
      this.$parentnav.addClass("has-active");

      // activate up the road
      this.$element.parents(".brandnav").each(function () {
        $(this)
          .parent()
          .addClass("active");
      });

      var shownEvent = $.Event("shown.tc.brandnav", {
        target: this.$element,
        relatedTarget: this.$parentnav
      });
      
      this.$parentnav
      .one("transitionend", function () {
        setTimeout(function () {
          that.$main.trigger(shownEvent);
          that.$body.css("overflow-x", "");
        });
      })
    }

    hide() {
      var that = this;

      var hideEvent = $.Event("hide.tc.brandnav", {
        target: this.$parentnav,
        relatedTarget: this.$element
      });

      this.$main.trigger(hideEvent);
      if (hideEvent.isDefaultPrevented()) return;

      this.$body.css("overflow-x", "hidden");

      var hiddenEvent = $.Event("hidden.tc.brandnav", {
        target: this.$parentnav,
        relatedTarget: this.$element
      });

      this.$parentnav
      .one("transitionend", function () {
        that.$parent.removeClass("active");
        that.$body.css("overflow-x", "");
        setTimeout(function () {
          that.$main.trigger(hiddenEvent);
        });
      })

      this.$parentnav.removeClass("has-active");
    }

    // Private

    static getSiblingNav($this) {
      var selector = $this.attr("data-target");

      if (!selector) {
        selector = $this.attr("href");
        selector =
          selector &&
          /#[A-Za-z]/.test(selector) &&
          selector.replace(/.*(?=#[^\s]*$)/, ""); // strip for ie7
      }

      var $parent = selector && $(selector);

      return $parent && $parent.length ? $parent : $this.siblings(".brandnav");
    }

    static getMain($this) {
      return $this.closest(".brandnav-lvl-container-1").last();
    }

    static getCurrentFromTarget($target) {
      return $target.find(".active > .brandnav").last();
    }

    // Static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this);
        let data = $element.data(DATA_KEY);

        if (!data) {
          data = new BrandNav(this);
          $element.data(DATA_KEY, data);
        }

        if (config === "show") {
          data[config](this);
        }

        if (config === "hide") {
          data[config](this);
        }
      });
    }

    static closeCurrent(e) {
      e.preventDefault();
      var $target = BrandNav.getSiblingNav($(this));
      var $activeNav = BrandNav.getCurrentFromTarget($target);
      BrandNav._jQueryInterface.call($activeNav, "hide", this)
    }

    static clearMenus(e) {
      if (e && e.which === 3) return;

      $(Selector.OPEN).each(function (a, b) {
        var $trigger = $(this);
        var $target = BrandNav.getSiblingNav($trigger);
        var $main = BrandNav.getMain($target);
        var $parent = $main.parent();

        if (!$parent.hasClass("active")) return;

        if (e && e.type == "click" && $.contains($main[0], e.target)) return;

        if (e.isDefaultPrevented()) return;

        $parent.find(".brandnav").each(function () {
          var $nav = $(this);
          $nav.removeClass("has-active");
          $nav.parent().removeClass("active");
        });
      });
    }

    static clickHandler(e) {
      e.preventDefault();
      var $target = BrandNav.getSiblingNav($(this));
      BrandNav._jQueryInterface.call($target, "show", this)
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document).on(
    Event.CLICK_DATA_API,
    BrandNav.clearMenus
  );

  $(document).on(
    Event.CLICK_DATA_API,
    Selector.OPEN,
    BrandNav.clickHandler
  );

  $(document).on(
    Event.CLICK_DATA_API,
    Selector.CLOSE,
    BrandNav.closeCurrent
  );

  $(document).on(
    Event.CLICK_DATA_API,
    Selector.CANCEL,
    BrandNav.clearMenus
  );

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = BrandNav._jQueryInterface;
  $.fn[NAME].Constructor = BrandNav;
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return BrandNav._jQueryInterface;
  };

  return BrandNav;
})($);

export default BrandNav;

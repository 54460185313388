import $ from 'jquery';

/**
 * --------------------------------------------------------------------------
 * XXXXXXXX (v4.0.0): search.js
 * Licensed under YYYYYYYY
 * --------------------------------------------------------------------------
 */

const Search = ($ => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'search';
  const VERSION = '4.0.0';
  const DATA_KEY = 'tc.search';
  const EVENT_KEY = `.${DATA_KEY}`;
  const DATA_API_KEY = '.data-api';
  const JQUERY_NO_CONFLICT = $.fn[NAME];

  const Selector = {
    TOGGLE: '[data-toggle="search"]'
  };

  const Event = {
    CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`,
    BLUR_DISMISS: `blur.dismiss${EVENT_KEY}`,
    KEYDOWN_DISMISS: `keydown.dismiss${EVENT_KEY}`,
    HIDE: `hide${EVENT_KEY}`,
    HIDDEN: `hidden${EVENT_KEY}`,
    SHOW: `show${EVENT_KEY}`,
    SHOWN: `shown${EVENT_KEY}`
  };

  const DEFAULTS = {
    toggle: true,
    keyboard: true,
    onBlur: false
  };

  const ClassName = {
    SEARCH: 'search',
    IN: 'in'
  };

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Search {
    constructor(element, options) {
      this.$element = $(element);
      this.options = $.extend({}, Search.DEFAULTS, options);

      this.$trigger = $(
        `${Selector.TOGGLE}[href="#${element.id}"],${
          Selector.TOGGLE
        }[data-target="#${element.id}"]`
      );
      this.transitioning = null;

      if (this.options.toggle) this.toggle();
    }

    // Getters

    static get VERSION() {
      return VERSION;
    }

    // Public

    show() {
      if (this.transitioning || this.$element.hasClass(ClassName.IN)) return;

      var startEvent = $.Event(Event.SHOW);
      this.$element.trigger(startEvent);
      if (startEvent.isDefaultPrevented()) return;

      this.$element.removeClass(ClassName.SEARCH).attr('aria-expanded', true);

      this.$trigger.attr('aria-expanded', true).blur();

      this.transitioning = 1;

      var complete = function() {
        this.$element.addClass(`${ClassName.SEARCH} ${ClassName.IN}`);
        this.transitioning = 0;
        this.enforceFocus();
        this.escape();
        this.$element.trigger(Event.SHOWN);

        if (this.options.onBlur === 'dismiss') {
          this.$element.on(
            Event.BLUR_DISMISS,
            'input',
            $.proxy(this.hide, this)
          );
        }
      };

      if (!$.support.transition) return complete.call(this);

      this.$element.one('transitionend', $.proxy(complete, this));
    }

    hide() {
      if (this.transitioning || !this.$element.hasClass(ClassName.IN)) return;

      var startEvent = $.Event(Event.HIDE);
      this.$element.trigger(startEvent);

      if (startEvent.isDefaultPrevented()) return;

      this.$element
        .removeClass(`${ClassName.SEARCH} ${ClassName.IN}`)
        .attr('aria-expanded', false);

      this.$trigger.attr('aria-expanded', false);

      this.transitioning = 1;

      var complete = function() {
        this.transitioning = 0;
        this.$element.addClass(ClassName.SEARCH).trigger(Event.HIDDEN);

        this.$element.off(Event.BLUR_DISMISS, 'input');
      };

      if (!$.support.transition) return complete.call(this);

      this.$element.one('transitionend', $.proxy(complete, this));
    }

    toggle() {
      this[this.$element.hasClass(ClassName.IN) ? 'hide' : 'show']();
    }

    enforceFocus() {
      this.$element.find('input').trigger('focus');
    }

    escape() {
      if (this.$element.hasClass(ClassName.IN) && this.options.keyboard) {
        this.$element.on(
          Event.KEYDOWN_DISMISS,
          $.proxy(function(e) {
            e.which == 27 && this.hide();
          }, this)
        );
      } else if (!this.$element.hasClass(ClassName.IN)) {
        this.$element.off(Event.KEYDOWN_DISMISS);
      }
    }

    // Private

    // Static

    static _jQueryInterface(option) {
      return this.each(function() {
        var $this = $(this);
        var data = $this.data(DATA_KEY);
        var options = $.extend(
          {},
          Search.DEFAULTS,
          $this.data(),
          typeof option == 'object' && option
        );

        if (!data && options.toggle && /show|hide/.test(option)) {
          options.toggle = false;
        }

        if (!data) $this.data(DATA_KEY, (data = new Search(this, options)));

        if (typeof option == 'string') data[option]();
      });
    }

    static getTargetFromTrigger($trigger) {
      var href;
      var target =
        $trigger.attr('data-target') ||
        ((href = $trigger.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, '')); // strip for ie7

      return $(target);
    }

    static clickHandler(e) {
      var $this = $(this);

      if (!$this.attr('data-target')) e.preventDefault();

      var $target = Search.getTargetFromTrigger($this);
      var data = $target.data(DATA_KEY);
      var option = data ? 'toggle' : $this.data();

      Search._jQueryInterface.call($target, option);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document).on(Event.CLICK_DATA_API, Selector.TOGGLE, Search.clickHandler);

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Search._jQueryInterface;
  $.fn[NAME].Constructor = Search;
  $.fn[NAME].noConflict = function() {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return Search._jQueryInterface;
  };

  return Search;
})($);

export default Search;
